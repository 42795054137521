import { Accordion, AccordionDetails, AccordionSummary, Container, Stack, styled } from "@mui/material";
import { themeColors } from "constants/colors.const";

export const HistoryContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    gap: "64px",
    flex: "1",
    [theme.breakpoints.down("lmd")]: {
        paddingLeft: "0px",
        paddingRight: "0px",
    },
}));

export const HistorySection = styled("div")(({ theme }) => ({
    width: "100%",
    maxWidth: "1008px",
    margin: "0 auto",
    marginTop: "-45px",
    flex: "1 1 100%",
    background: theme.palette.brandColors.brandWhite,
    borderRadius: "48px 48px 0px 0px",
    overflow: "hidden",
    position: "relative",
    zIndex: 1,
    padding: "16px 0 116px",
    "&::before": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        background: "linear-gradient(270deg, #7DCDD6 0%, #FFFDEB 100%)",
        opacity: "30%",
        zIndex: -1,
    },
    [theme.breakpoints.up("lmd")]: {
        marginTop: "0",
        flex: "1 0 390px",
    },
}));

export const HistoryItemAccordion = styled(Accordion)(({ theme }) => ({
    borderRadius: "16px !important",
    overflow: "hidden",
    border: `2px solid ${theme.palette.brandColors.brandGreen}`,
    "&.Mui-disabled": {
        backgroundColor: theme.palette.text.secondary,
    },
    "&.Mui-expanded": {
        margin: "0 !important",
    },
}));

export const HistoryItemTrigger = styled(AccordionSummary)(() => ({
    padding: "16px 8px 16px 16px",

    "&.Mui-expanded": {
        minHeight: "48px",
    },

    "&.Mui-disabled": {
        opacity: 1,
    },
    "& .MuiAccordionSummary-content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0",
        margin: "0",
        gap: "8px",

        "&.Mui-expanded": {
            margin: "0 !important",
        },
    },
}));

export const HistoryItemDetails = styled(AccordionDetails)(() => ({
    paddingTop: "0",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
}));

export const LocationInfoWrapper = styled(Stack)(() => ({
    paddingTop: "12px",
    borderTop: `2px solid ${themeColors.state.buttonHoverBg}`,
}));
