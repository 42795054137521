import { FC } from "react";
import z from "zod";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, DialogProps, Stack } from "@mui/material";
import { SlideInPopup, CloseButton } from "components/SlideInPopup";
import { InputTextFieldLight, TypographyWithHighlight } from "components/shared";
import { useLocationsStore } from "providers/RootStoreProvider";

type RenameItemPopupProps = DialogProps & Record<"locationName" | "locationId", string>;

const RenameItemPopup: FC<RenameItemPopupProps> = ({ onClose, locationId, locationName, ...props }) => {
    const { t } = useTranslation();
    // const { featureKey } = useAccountFeature();
    const { renameLocationAction } = useLocationsStore();

    const {
        control,
        handleSubmit,
        reset,
        setError,
        formState: { isSubmitting, isValid },
    } = useForm<{ name: string }>({
        resolver: zodResolver(z.object({ name: z.string().trim().min(1) })),
    });

    const closePopup = (event?: any, reason?: "backdropClick" | "escapeKeyDown") => {
        if (isSubmitting) {
            return;
        }

        onClose?.(event ?? {}, reason ?? "backdropClick");
        reset();
    };

    const onSubmit: SubmitHandler<{ name: string }> = async (data) => {
        try {
            await renameLocationAction(locationId, data.name);

            // await handleAmpliLoaded(() => ampli.numberNameChanged({ mode: featureKey }));

            closePopup();
        } catch (e) {
            setError("name", { message: t("Oops") });
        }
    };

    return (
        <SlideInPopup
            slotProps={{
                root: {
                    onClick: (e) => e.stopPropagation(),
                },
            }}
            onClose={onClose}
            sx={(theme) => ({
                ".MuiDialog-paper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    padding: "38px 45px",
                },
                [theme.breakpoints.down("lmd")]: {
                    ".MuiDialog-paper": {
                        overflow: "unset",
                        minHeight: "90vh",
                        padding: "44px 16px 16px",
                    },
                },
            })}
            {...props}
            titleComponent={
                <Stack sx={{ textAlign: "center", alignItems: "center" }}>
                    <CloseButton onClick={closePopup} />
                    <TypographyWithHighlight
                        component="span"
                        variant="dmSansH5Semibold"
                        fontSize="20px"
                        text={t("rename_popup_title", { name: locationName })}
                        highlight={locationName}
                        highlightProps={{
                            dir: "ltr",
                            sx: { whiteSpace: "nowrap" },
                        }}
                    />
                </Stack>
            }
            contentComponent={
                <Stack onSubmit={handleSubmit(onSubmit)} component="form" gap="44px">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <InputTextFieldLight
                                {...field}
                                placeholder={t("Type new title")}
                                error={!!error?.message}
                                inputProps={{ maxLength: 50 }}
                                sx={(theme) => ({
                                    ".MuiInputBase-root": {
                                        backgroundColor: theme.palette.background.lightAccent,
                                    },
                                    ".MuiInputBase-input": {
                                        backgroundColor: theme.palette.background.lightAccent,
                                        ...theme.typography.dmSansH5Semibold,
                                        fontWeight: 500,
                                        fontSize: "20px",
                                    },
                                    ".MuiInputBase-input::placeholder": {
                                        ...theme.typography.dmSansH5Semibold,
                                        fontWeight: 500,
                                        fontSize: "20px",
                                    },
                                })}
                            />
                        )}
                    />
                    <Stack sx={{ gap: { xs: "16px", lmd: "24px" }, flexDirection: { xs: "column", lmd: "row-reverse" } }}>
                        <Button
                            variant="themePrimary"
                            type="submit"
                            disabled={isSubmitting || !isValid}
                            sx={{ whiteSpace: "nowrap", padding: "18px 40px", minWidth: "unset", height: "60px" }}
                        >
                            {t("Save")}
                        </Button>
                        <Button
                            onClick={closePopup}
                            fullWidth
                            variant="outlined"
                            disabled={isSubmitting}
                            sx={(theme) => ({
                                color: theme.palette.text.disabled,
                                borderColor: theme.palette.text.disabled,
                                background: "none",
                                borderWidth: "2px",
                                width: "fit-content",
                                minWidth: { xs: "100%", lmd: "186px" },
                            })}
                        >
                            {t("Cancel")}
                        </Button>
                    </Stack>
                </Stack>
            }
        />
    );
};

export default RenameItemPopup;
