import { Container, Stack, styled } from "@mui/material";

export const MobileMenuContainer = styled(Stack)(({ theme }) => ({
    flexDirection: "row",
    alignItems: "center",
    gap: "24px",
    padding: "0 24px",
    position: "fixed",
    bottom: "4px",
    left: 0,
    right: 0,
    height: "64px",
    borderRadius: "64px",
    backgroundColor: theme.palette.background.secondary,
    zIndex: 4,
}));

export const FeatureLinksContainer = styled(Stack)(() => ({
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "64px",
    width: "100%",
}));

export const DesktopMenuWrapper = styled(Stack)(({ theme }) => ({
    backgroundColor: theme.palette.background.secondary,
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    height: "64px",
    flexDirection: "row",
    alignItems: "center",
    transition: theme.transitions.create(["background-color"], {
        duration: theme.transitions.duration.short,
    }),
}));

export const DesktopMenuContainer = styled(Container)({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
});
