import { FC, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { LocationType } from "interfaces/location/location.interface";
import { TelephoneForm } from "components/TelephoneForm";
import { useAppData, useLocationsStore, useUserStore } from "providers/RootStoreProvider";
import { useBoolean } from "hooks/useBoolean";
import SuccessPopup, { ScrollElementProps } from "./SuccessPopup";
import LostPhoneSendPopup from "./LostPhoneSendPopup";
import { FormContainer, FormSection } from "./Form.styled";
import { useHistoryFilter } from "../Filter";

interface LostPhoneFormProps extends ScrollElementProps {
    redirectPath?: string;
}

const LostPhoneForm: FC<LostPhoneFormProps> = observer(({ scrollToElement, redirectPath }) => {
    // const navigate = useNavigate();
    const { searchPhoneNumber } = useAppData();
    const { t } = useTranslation();
    const { userSubscription } = useUserStore();
    // const { featureKey } = useAccountFeature();
    const { overLimit, loading, fetchLocations } = useLocationsStore();
    const [isConfirmOpen, setConfirm] = useBoolean();
    const [isSuccessOpen, setSuccess] = useBoolean();

    const {
        state: { isActive },
        clearAll,
    } = useHistoryFilter();

    const onLocate = useCallback(() => {
        // if (!userSubscription?.isPremium) {
        //     return navigate(AppRoutes.PAYMENT);
        // }

        setConfirm.on();
    }, [userSubscription?.isPremium]);

    const onSuccess = async () => {
        // const link = await getLocationLink({
        //     name: searchPhoneNumber,
        //     type: LocationType.LOST_PHONE,
        //     lostPhoneInfo: data,
        // });
        // const locationId = link?.data?.id;

        // if (locationId) {
        //     await sendLocationSms({ locationId, language: i18n.language, flowOuter: flowOuter ?? false });
        // }
        // await handleAmpliLoaded(() =>
        //     ampli.messageSentSuccess({
        //         mode: featureKey,
        //         email: data.email,
        //         entered_reward: data.reward,
        //         entered_number: data.alternativePhone,
        //     })
        // );
        if (isActive) {
            clearAll();
        } else {
            await fetchLocations({ type: LocationType.LOST_PHONE });
        }
        onSendPopupClose();
        setSuccess.on();
    };

    const onSendPopupClose = async () => {
        // await handleAmpliLoaded(() => {
        //     ampli.messageExit({ mode: featureKey });
        // });
        setConfirm.off();
    };

    return (
        <FormSection>
            <FormContainer maxWidth="lg" sx={{ gap: "8px" }}>
                <Typography component="h1" variant="dmSansH4Semibold" sx={{ color: { xs: "text.secondary", lmd: "text.primary" } }}>
                    {t("An effective method to locate your lost phone")}
                </Typography>
                <Typography variant="title2Regular" sx={{ mb: "24px", color: { xs: "text.disabled", lmd: "background.lightGrey" } }}>
                    {t("Request the return of the phone by sending a text ")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", lmd: "row" },
                        gap: "16px",
                        height: { xs: "155px", lmd: "unset" },
                        ".MuiInputBase-root": { height: "60px !important" },
                    }}
                >
                    <TelephoneForm
                        disabled={overLimit || loading}
                        initValue={searchPhoneNumber}
                        onSubmit={onLocate}
                        redirectPath={redirectPath}
                        buttonProps={{
                            children: t("Continue"),
                            sx: {
                                width: { xs: "100%", sm: "350px", lmd: "100%" },
                                padding: "18px",
                                height: "60px",
                            },
                        }}
                        wrapperProps={{
                            sx: {
                                "& label span": {
                                    color: { lmd: "text.tertiary" },
                                },
                            },
                        }}
                    />
                </Box>
            </FormContainer>
            <LostPhoneSendPopup open={isConfirmOpen} onClose={onSendPopupClose} onSuccess={onSuccess} />
            <SuccessPopup open={isSuccessOpen} onClose={setSuccess.off} scrollToElement={scrollToElement} />
        </FormSection>
    );
});

export default LostPhoneForm;
