import { Languages } from "enums/languages.enum";
import { saveLanguage } from "helpers/language.helpers";
import { useEffect } from "react";

export const useSidebarLogic = ({
    open,
    // landing_type,
    onClose,
}: {
    open?: boolean;
    // landing_type: "locate by number legal" | "locate by number non legal" | "lost my phone";
    onClose: () => void;
}) => {
    useEffect(() => {
        if (document.body && typeof open !== "undefined") {
            document.body.style.overflowY = open ? "hidden" : "unset";
        }
    }, [open]);

    const handleLanguagePick = (id: Languages, closeMenu?: boolean) => {
        saveLanguage(id.toUpperCase());
        if (closeMenu) {
            setTimeout(() => onClose(), 300);
        }
    };

    const handleMenuItemClick = () => {
        // handleAmpliLoaded(() =>
        //     ampli.landingMenuClicked({
        //         chosen_navigation_item: title, //title - add to function argument
        //         landing_type,
        //     })
        // );
        onClose();
    };

    return {
        handleLanguagePick,
        handleMenuItemClick,
    };
};
