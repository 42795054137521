import { forwardRef, useState } from "react";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { ILocation } from "interfaces/location/location.interface";
import { HistoryContainer } from "./History.styled";
import HistorySectionWrapper from "./HistorySectionWrapper";
import HistoryList from "./HistoryList";
import HistoryMap from "./HistoryMap";

const History = forwardRef<HTMLDivElement>((_, ref) => {
    const [activeLocation, setActiveLocation] = useState<ILocation | null>(null);
    const { isTablet } = useDeviceDetect();

    return (
        <HistoryContainer maxWidth="lg" ref={ref}>
            <HistorySectionWrapper>
                <HistoryList activeItem={activeLocation} setActiveItem={setActiveLocation} withMap={isTablet} />
            </HistorySectionWrapper>
            {!isTablet && <HistoryMap location={activeLocation} />}
        </HistoryContainer>
    );
});

export default History;

History.displayName = "History";
