import { useTranslation } from "react-i18next";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useBoolean } from "hooks/useBoolean";
import { LocationStatusChip } from "components/shared";
import { SlideInPopup, CloseButton } from "components/SlideInPopup";

import { LocationStatus } from "interfaces/location/location.interface";

import { ReactComponent as InfoIcon } from "assets/icons/Info.svg";

const HistoryStatusesPopup = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useBoolean();

    const statusItemSx = {
        height: "auto",
        whiteSpace: "unset",
        ".MuiChip-label": {
            whiteSpace: "unset",
        },
    };

    return (
        <>
            <InfoIcon style={{ cursor: "pointer" }} onClick={setOpen.on} />
            <SlideInPopup
                open={open}
                onClose={setOpen.off}
                PaperProps={{
                    sx: {
                        padding: "44px 24px",
                    },
                }}
                titleComponent={
                    <>
                        <CloseButton onClick={setOpen.off} />
                        <Typography component="p" variant="dmSansH5Semibold" marginBottom="24px" align="center">
                            {t("About the statuses of a number’s location")}
                        </Typography>
                    </>
                }
                contentComponent={
                    <Stack direction="column" gap="24px">
                        <Stack direction="row" gap="15px" alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                            <Box minWidth="100px">
                                <LocationStatusChip sx={statusItemSx} status={LocationStatus.PENDING} />
                            </Box>
                            <Typography variant="dmSansBody2Regular">{t("pending_meaning")}</Typography>
                        </Stack>
                        <Stack direction="row" gap="15px" alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                            <Box minWidth="100px">
                                <LocationStatusChip sx={statusItemSx} status={LocationStatus.API_LOCATED} />
                            </Box>
                            <Typography variant="dmSansBody2Regular">{t("ip_located_meaning")}</Typography>
                        </Stack>
                        <Stack direction="row" gap="15px" alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                            <Box minWidth="100px">
                                <LocationStatusChip sx={statusItemSx} status={LocationStatus.GPS_LOCATED} />
                            </Box>
                            <Typography variant="dmSansBody2Regular">{t("gps_located_meaning")}</Typography>
                        </Stack>
                    </Stack>
                }
            />
        </>
    );
};

export default HistoryStatusesPopup;
