import { FC, useCallback } from "react";
import { Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as CheckedIcon } from "assets/icons/checked.svg";
import { LocationStatus } from "interfaces/location/location.interface";

const statusFilterList = [
    { label: "All", values: ["all"] },
    { label: "GPS Located", values: [LocationStatus.GPS_LOCATED] },
    { label: "IP Located", values: [LocationStatus.API_LOCATED] },
    { label: "Pending", values: [LocationStatus.PENDING, LocationStatus.SENT] },
];

const StatusFilter = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const statusQuery = searchParams.get("status");
    // const { featureKey } = useAccountFeature();

    const updateFilter = useCallback(
        (values: string[]) => async () => {
            if (values.includes("all")) {
                searchParams.delete("status");
            } else {
                const currentFilters = statusQuery?.split(",") || [];
                const allValuesSelected = values.every((value) => currentFilters.includes(value));

                if (allValuesSelected) {
                    const newFilters = currentFilters.filter((status) => !values.includes(status));
                    if (newFilters.length > 0) {
                        searchParams.set("status", newFilters.join(","));
                    } else {
                        searchParams.delete("status");
                    }
                } else {
                    const uniqueNewFilters = [...new Set([...currentFilters, ...values])];

                    if (uniqueNewFilters.length < 4) {
                        searchParams.set("status", uniqueNewFilters.join(","));
                    } else {
                        searchParams.delete("status");
                    }
                }
            }

            // await handleAmpliLoaded(() =>
            //     ampli.filterStatusSelected({
            //         mode: featureKey,
            //         status_type: searchParams.get("status") ?? "All",
            //     })
            // );

            setSearchParams(searchParams);
        },
        [statusQuery]
    );

    const isActive = useCallback(
        (values: string[]) => {
            const currentFilters = statusQuery?.split(",") || [];

            if (values.includes("all") && currentFilters.length === 0) {
                return true;
            }

            return values.every((value) => currentFilters.includes(value));
        },
        [statusQuery]
    );

    return (
        <Stack
            direction="row"
            gap="16px"
            flexWrap="nowrap"
            paddingX="16px"
            overflow="auto"
            sx={{
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            {statusFilterList.map((status) => {
                return (
                    <StatusChip key={status.values.join("/")} label={t(status.label)} active={isActive(status.values)} onClick={updateFilter(status.values)} />
                );
            })}
        </Stack>
    );
};

export default StatusFilter;

interface StatusChipProps {
    label: string;
    active: boolean;
    onClick: () => void;
}

const StatusChip: FC<StatusChipProps> = ({ label, active, onClick }) => {
    return (
        <Chip
            label={label}
            size="small"
            icon={active ? <CheckedIcon /> : undefined}
            onClick={onClick}
            sx={(theme) => ({
                cursor: "pointer",
                height: "28px",
                gap: "6px",
                borderRadius: "6px",
                padding: "2px 8px",
                width: "fit-content",
                ...theme.typography.dmSansBody1Regular,
                fontWeight: active ? 600 : 400,
                color: active ? theme.palette.text.secondary : theme.palette.text.tertiary,
                background: active ? "#369D8A" : theme.palette.background.quaternary,
                "& .MuiChip-label": {
                    padding: "0",
                },
                "& .MuiChip-icon": {
                    color: "currentColor",
                    width: "16px",
                    height: "16px",
                    margin: "0",
                },
                [theme.breakpoints.down("lmd")]: {
                    "&:hover": {
                        background: active ? "#369D8A" : theme.palette.background.quaternary,
                    },
                },
            })}
        />
    );
};
