import dayjs from "dayjs";
import { formatNumber } from "libphonenumber-js";
import { wrapPhoneOperatorIntoBrackets } from "helpers/wrapPhoneOperatorIntoBrackets";

export const formatCoordinate = (coordinate?: string | null) => {
    const formattedCoordinate = coordinate ? parseFloat(coordinate).toFixed(4) : "-";
    return formattedCoordinate;
};

export const formatDate = (date?: string | null) => {
    const formattedDate = date ? dayjs(date).format("DD.MM.YYYY • hh:mm A") : "-";
    return formattedDate;
};

export const formatPhone = (number?: string) => number && wrapPhoneOperatorIntoBrackets(formatNumber(number, "INTERNATIONAL"));
