import { Checkbox, SxProps, CheckboxProps } from "@mui/material";
import { themeColors } from "constants/colors.const";
import { FC } from "react";

interface Props extends CheckboxProps {
    sx?: SxProps;
}

export const CheckboxPrimary: FC<Props> = ({ sx, ...rest }) => {
    return (
        <Checkbox
            sx={{
                mt: "-9px",
                ml: "-9px",
                color: themeColors.text.grey,
                "&.Mui-checked": {
                    color: themeColors.brandColors.brandGreen,
                    "& .MuiSvgIcon-root": {
                        borderColor: themeColors.brandColors.brandGreen,
                    },
                },
                ...sx,
            }}
            inputProps={{ "aria-label": "controlled" }}
            {...rest}
        />
    );
};
