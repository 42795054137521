import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import GoogleMap from "components/Map/GoogleMap";
import { ILocation, LocationStatus } from "interfaces/location/location.interface";
import { LocationInfoWrapper } from "../History.styled";
import { formatCoordinate, formatDate } from "../utils";
import HistoryItemRow from "./HistoryItemRow";

const HistoryDetails: FC<ILocation & { withMap?: boolean }> = ({ status, latitude, longitude, createdAt, updatedAt, details, withMap }) => {
    const { t } = useTranslation();

    const isGPSLocated = status === LocationStatus.GPS_LOCATED;

    const deviceInfo = details?.deviceInfo;
    const location = deviceInfo?.country && deviceInfo?.city && `${deviceInfo?.country}, ${deviceInfo?.city}`;

    return (
        <>
            <LocationInfoWrapper gap="8px">
                <HistoryItemRow title={t("Location")} value={location} />
                <HistoryItemRow title={t("Coordinates")} value={`${t("lat")} ${formatCoordinate(latitude)}, ${t("long")} ${formatCoordinate(longitude)}`} />
                <HistoryItemRow title={t("Accuracy")} value={isGPSLocated ? t("high_accuracy") : t("low_accuracy")} />
            </LocationInfoWrapper>
            <LocationInfoWrapper gap="4px">
                <HistoryItemRow title={t("Request sent")} value={formatDate(createdAt)} variant="small" />
                <HistoryItemRow title={t("Located on")} value={formatDate(updatedAt)} variant="small" />
                <HistoryItemRow title={t("IP")} value={deviceInfo?.ip} variant="small" />
                <HistoryItemRow title={t("Device type")} value={deviceInfo?.deviceType} variant="small" />
                <HistoryItemRow title={t("OS name")} value={deviceInfo?.osName} variant="small" />
                <HistoryItemRow title={t("OS version")} value={deviceInfo?.osVersion} variant="small" />
                <HistoryItemRow title={t("Browser name")} value={deviceInfo?.browserName} variant="small" />
                <HistoryItemRow title={t("Browser version")} value={deviceInfo?.browserVersion} variant="small" />
                <HistoryItemRow title={t("Language")} value={deviceInfo?.language} variant="small" />
                <HistoryItemRow title={t("Screen size")} value={deviceInfo?.screenSize} variant="small" />
            </LocationInfoWrapper>
            {withMap && (
                <Stack height="30vh">
                    <GoogleMap
                        location={
                            {
                                longitude,
                                latitude,
                            } as ILocation
                        }
                        borderRadius="8px"
                        height="100%"
                    />
                </Stack>
            )}
        </>
    );
};

export default HistoryDetails;
