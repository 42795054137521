import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { HistorySection } from "./History.styled";
import HistoryStatusesPopup from "./HistoryStatusesPopup";
import HistoryLimit from "./HistoryLimit";
import { StatusFilter } from "../Filter";

const HistorySectionWrapper: FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation();

    return (
        <HistorySection>
            <Box position="absolute" right="22px" top="24px">
                <HistoryStatusesPopup />
            </Box>
            <Stack gap="16px" marginBottom="24px">
                <Typography component="h2" variant="dmSansH5Regular" align="center">
                    {t("History")}
                </Typography>
                <HistoryLimit />
                <Divider sx={(theme) => ({ borderColor: theme.palette.background.default, borderWidth: "2px" })} />
                <StatusFilter />
            </Stack>
            {children}
        </HistorySection>
    );
};

export default HistorySectionWrapper;
