import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let UnsubscribeSms = lazy(() => import("./UnsubscribeSms"));

export async function lazyUnsubscribeSmsLoader() {
    const componentModule = await import("./UnsubscribeSms");
    UnsubscribeSms = componentModule.default as any;

    return null;
}

export function LazyUnsubscribeSms() {
    return (
        <Suspense fallback={<PageFallback />}>
            <UnsubscribeSms />
        </Suspense>
    );
}
