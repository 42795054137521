import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Stack } from "@mui/material";
import { useLocationsStore } from "providers/RootStoreProvider";
import { LocationType } from "interfaces/location/location.interface";
import { History } from "./History";
import { LostPhoneForm } from "./Form";
import { useHistoryFilter } from "./Filter";

const LostPhone = observer(() => {
    const historySectionRef = useRef<HTMLDivElement>(null);
    const { fetchLocations } = useLocationsStore();
    const { filters } = useHistoryFilter();
    const { status } = filters;

    const triggerAmpliEvent = async () => {
        // await handleAmpliLoaded(() => {
        //     ampli.accountScreenShown({
        //         mode: LOST_MY_PHONE_MODE,
        //     });
        // });
    };

    useEffect(() => {
        fetchLocations({
            type: LocationType.LOST_PHONE,
            status: status ?? undefined,
        });
        triggerAmpliEvent();
    }, [status]);

    return (
        <Stack direction="column" flex="1 1 auto">
            <LostPhoneForm scrollToElement={historySectionRef} />
            <History ref={historySectionRef} />
        </Stack>
    );
});

export default LostPhone;
