import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ILocation, LocationType } from "interfaces/location/location.interface";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useLocationsStore } from "providers/RootStoreProvider";
import { useBoolean } from "hooks/useBoolean";
import HistoryEmpty from "./HistoryEmpty";
import { AccordionItemProps, FindDeviceAccordionItem, LostPhoneAccordionItem } from "./HistoryItem";

type HistoryListProps = Pick<AccordionItemProps<ILocation>, "activeItem" | "setActiveItem" | "withMap">;

const HistoryList: FC<HistoryListProps> = observer(({ setActiveItem, activeItem, withMap }) => {
    const { t } = useTranslation();
    const { locations, loading } = useLocationsStore();
    const [isExpanded, setExpand] = useBoolean();

    const HistoryItem = {
        [LocationType.FIND_DEVICE]: FindDeviceAccordionItem,
        [LocationType.LOST_PHONE]: LostPhoneAccordionItem,
    };

    const renderAccordionList = useCallback(() => {
        if (loading) {
            return new Array(3).fill("").map((_, idx) => (
                <Skeleton
                    key={idx}
                    variant="rectangular"
                    width="100%"
                    height={56}
                    sx={{
                        borderRadius: "16px",
                    }}
                />
            ));
        }

        if (!loading && locations?.length === 0) {
            return <HistoryEmpty />;
        }

        const list = locations.map((location) => {
            const Component = HistoryItem[location.type] as FC<AccordionItemProps<ILocation>>;
            return <Component key={location.id} activeItem={activeItem} setActiveItem={setActiveItem} location={location} withMap={withMap} />;
        });

        const initialList = list.slice(0, 3);
        const expandedList = list.slice(3);

        return (
            <>
                {initialList}
                {isExpanded && expandedList}
                {!!expandedList && !!expandedList?.length && (
                    <Typography
                        variant="smallRegular"
                        onClick={setExpand.toggle}
                        sx={(theme) => ({
                            width: "fit-content",
                            margin: "0 auto",
                            textDecoration: "underline",
                            display: "block",
                            cursor: "pointer",
                            color: theme.palette.brandColors.brandGreen,
                        })}
                    >
                        {isExpanded ? t("Close") : t("See more")}
                    </Typography>
                )}
            </>
        );
    }, [loading, locations, isExpanded, setExpand]);

    return (
        <Stack gap="8px" paddingX="40px">
            {renderAccordionList()}
        </Stack>
    );
});

export default HistoryList;
