import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

enum LocationFilterKey {
    STATUS = "status",
}

type FilterValues = string | null;

type Filters = {
    [key in LocationFilterKey]?: FilterValues;
};

export const useHistoryFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const filters = useMemo(() => {
        return Object.values(LocationFilterKey).reduce((prev: Filters, curr) => {
            const value: FilterValues = searchParams.get(curr);
            if (value !== null) {
                prev[curr] = value;
            }
            return prev;
        }, {});
    }, [searchParams]);

    const isActive = useMemo(() => {
        return Boolean(Object.values(filters).length > 0);
    }, [filters]);

    const clearAll = useCallback(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        Object.values(LocationFilterKey).forEach((key) => {
            if (newSearchParams.has(key)) {
                newSearchParams.delete(key);
            }
        });
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return {
        filters,
        clearAll,
        state: {
            isActive,
        },
    };
};
