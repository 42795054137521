import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, FormHelperText, MenuItem, Stack, Typography } from "@mui/material";
import { LoadingButton } from "components/LoadingButton";
import { useTranslation } from "react-i18next";
import { schema, FormData } from "./schema";
import { Select, TextArea, TextField } from "./components";
import { ReactComponent as ArrowIcon } from "assets/icons/select-arrow.svg";
import { sendSupportTicket } from "http-client/location.client";
import { FC } from "react";

const selectOptions = ["General product questions", "Payment was declined", "Technical issues", "Unsubscribe", "Refund"];

interface Props {
    onSuccess: () => void;
}

export const ContactUsForm: FC<Props> = ({ onSuccess }) => {
    const { control, handleSubmit, formState, reset, setError } = useForm<FormData>({
        resolver: zodResolver(schema),
    });
    const { t } = useTranslation();

    const rootErrorMessage = formState?.errors?.root;

    const onSubmit = async (data: FormData) => {
        try {
            await sendSupportTicket(data);
            reset();
            // handleAmpliLoaded(() => ampli.contactFormSent({ subject: data.subject }));
            onSuccess();
        } catch (error) {
            console.error("error: ", error);
            setError("root", {
                message: t("Oops! An error occured. Please try again later."),
            });
        }
    };

    return (
        <Stack component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => {
                    return (
                        <TextField
                            {...field}
                            label={<Typography variant="dmSansBody2Regular">{t("Your name")}:</Typography>}
                            variant="outlined"
                            placeholder={t("Name")}
                            error={!!error ?? !!rootErrorMessage}
                            helperText={error?.message}
                            sx={{ mb: "1rem" }}
                        />
                    );
                }}
            />
            <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        label={<Typography variant="dmSansBody2Regular">{t("Your email")}:</Typography>}
                        variant="outlined"
                        placeholder={t("Email")}
                        error={!!error ?? !!rootErrorMessage}
                        helperText={t(error?.message || "")}
                        sx={{ mb: "1rem" }}
                    />
                )}
            />
            <Controller
                name="subject"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <FormControl
                        fullWidth
                        error={!!error ?? !!rootErrorMessage}
                        sx={(theme) => ({
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    border: `1px solid ${theme.palette.brandColors.brandGreen} !important`,
                                },
                            },
                        })}
                    >
                        <Typography variant="dmSansBody2Regular" mb="4px">
                            {t("Subject")}:
                        </Typography>
                        <Select
                            {...field}
                            displayEmpty
                            IconComponent={ArrowIcon}
                            renderValue={(selected: any) => (
                                <Typography
                                    variant="dmSansBody2Semibold"
                                    fontSize="20px"
                                    color={(theme) => (selected === "" ? theme.palette.text.disabled : theme.palette.text.primary)}
                                    mt="5px"
                                    fontWeight={500}
                                    display="block"
                                >
                                    {selected === "" ? t("Select your subject") : t(selected)}
                                </Typography>
                            )}
                            onChange={(e) => field.onChange(e.target.value)}
                            value={field.value}
                            MenuProps={{
                                sx: {
                                    "& .MuiPaper-root": {
                                        transform: { xs: "translateY(10px) !important", lmd: "translateY(4px) !important" },
                                        backgroundColor: "rgba(243, 244, 247, .95)",
                                        boxShadow: "0px 10px 44px 0px rgba(0, 0, 0, 0.10)",
                                        backdropFilter: "blur(50%)",
                                        borderRadius: "8px",
                                        "& ul": {
                                            padding: 0,
                                        },
                                    },
                                },
                            }}
                        >
                            {selectOptions.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                    sx={(theme) => ({
                                        height: "47px",
                                        fontFamily: theme.typography.dmSansH3Semibold.fontFamily,
                                        "&:not(:last-of-type)": {
                                            borderBottom: `0.5px solid ${theme.palette.brandColors.brandLightgrey}`,
                                        },
                                        "&:hover": { backgroundColor: theme.palette.brandColors.brandGreen, color: theme.palette.text.secondary },
                                    })}
                                >
                                    {t(option)}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />
            <Controller
                name="message"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error ?? !!rootErrorMessage} variant="outlined">
                        <Typography variant="dmSansBody2Regular" mb="4px">
                            {t("How can we help?")}
                        </Typography>
                        <TextArea
                            {...field}
                            minRows={3}
                            sx={(theme) => ({ mb: "1.5rem", borderColor: error ? `${theme.palette.text.error} !important` : null })}
                            placeholder={t("Your message")}
                        />
                        {rootErrorMessage && <FormHelperText>{rootErrorMessage?.message}</FormHelperText>}
                    </FormControl>
                )}
            />
            <LoadingButton
                type="submit"
                variant="themePrimary"
                disableRipple
                disableElevation
                sx={{
                    textTransform: "uppercase",
                    width: { xs: "100%", lmd: "fit-content", maxWidth: "100%" },
                    alignSelf: "center",
                }}
                loading={formState.isSubmitting}
            >
                <Typography variant="bodyInterExtraBold" whiteSpace="nowrap" fontSize="23px">
                    {t("Send message")}
                </Typography>
            </LoadingButton>
        </Stack>
    );
};
