import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, BoxProps, Button, ButtonProps, Stack, Typography, debounce } from "@mui/material";
import { observer } from "mobx-react-lite";
import { matchIsValidTel, MuiTelInputInfo } from "mui-tel-input";
import { CountryCode, getExampleNumber, parsePhoneNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import { useTranslation } from "react-i18next";

import { getIpInfo } from "http-client/ip-info";
import { useAppData } from "providers/RootStoreProvider";
import { useExtendedAdornmentArea } from "../TelephoneInput/hooks";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { TelephoneInput, TelephoneInputLight } from "components/TelephoneInput";
import { blackList } from "./blackList.const";
import { CheckboxPrimary } from "components/shared/CheckboxPrimary";
import { useBoolean } from "hooks/useBoolean";

interface Props {
    redirectPath?: string;
    disabled?: boolean;
    initValue?: string;
    initPhoneInfo?: MuiTelInputInfo;
    sendValueCallback?: (value: string) => void;
    isLanguageLoadedHandler?: (() => void) | null;
    onSubmit?: () => void;
    variant?: "dark" | "light";
    buttonProps?: ButtonProps;
    wrapperProps?: BoxProps;
    onUnsubscribe?: (phone: string | number) => void;
    hideDisclaimer?: boolean;
}

export const TelephoneForm = observer(
    ({
        buttonProps,
        variant = "light",
        redirectPath,
        initPhoneInfo,
        disabled,
        initValue,
        sendValueCallback,
        isLanguageLoadedHandler,
        onSubmit,
        wrapperProps,
        onUnsubscribe,
        hideDisclaimer,
    }: Props) => {
        const navigate = useNavigate();
        // const { pathname } = useLocation();
        const { isMobile } = useDeviceDetect();
        const inputRef = useExtendedAdornmentArea();
        const { t } = useTranslation();

        const [checked, setChecked] = useBoolean(false);
        const [isInitialNumber, setIsInitialNumber] = useState(true);
        const [isValidInitValue, setIsValidInitValue] = useState<boolean | null>(!!(initValue && matchIsValidTel(initValue)));
        const [phoneValue, setPhoneValue] = useState(initValue || "");
        const [phoneInfo, setPhoneInfo] = useState<MuiTelInputInfo | Record<string, any> | undefined>(initPhoneInfo);
        const [exampleNumber, setExampleNumber] = useState("");

        const [isValidPhone, setIsValidPhone] = useState<boolean | null>(null);
        const { setSearchPhoneNumber, setSearchCountryCode, ipInfo, setIpInfo, flowOuter } = useAppData();
        const [loading, setLoading] = useState(!ipInfo && !isValidInitValue);

        useEffect(() => {
            if (initValue && !initPhoneInfo) {
                try {
                    const parsedNumber = parsePhoneNumber(initValue);
                    setPhoneInfo({
                        ...phoneInfo,
                        countryCode: parsedNumber.country,
                        numberValue: parsedNumber.number,
                    });
                } catch (error) {
                    console.error("Failed to parse phone number:", error);
                    setPhoneInfo(undefined);
                }
            }
        }, []);

        const isValidated = isValidPhone !== null;

        useEffect(() => {
            if (!ipInfo && !isValidInitValue) {
                setLoading(true);
                getIpInfo(flowOuter)
                    .then((response) => {
                        setIpInfo(response);
                    })
                    .finally(() => {
                        setTimeout(() => setLoading(false), 300);
                        if (isLanguageLoadedHandler) {
                            isLanguageLoadedHandler();
                        }
                    });
            }
        }, [ipInfo, initValue]);

        useEffect(() => {
            const currentCountryCode = phoneInfo?.countryCode;
            const placeholderCountryCode = currentCountryCode || ipInfo?.country;
            const currentExampleNumber = getExampleNumber(placeholderCountryCode as CountryCode, examples);
            if (currentExampleNumber) {
                setExampleNumber(currentExampleNumber.formatNational());
                setIsInitialNumber(false);
            }
        }, [phoneInfo?.countryCode, ipInfo?.country]);

        const handleChange = (newValue: string, info: MuiTelInputInfo) => {
            setIsValidPhone(null);
            setIsValidInitValue(isInitialNumber ? !!initValue : null);
            setPhoneValue(isInitialNumber && initValue ? initValue : newValue);
            setPhoneInfo(info);
            validatePhone(newValue, info);
            if (isInitialNumber) {
                setIsInitialNumber(false);
            }
        };

        const validatePhone = useCallback(
            debounce((phone: string, info: MuiTelInputInfo) => {
                if (!phone || phone === `+${info?.countryCallingCode}`) {
                    return;
                }
                const isValid = matchIsValidTel(phone);
                setIsValidPhone(isValid);
            }, 500),
            [matchIsValidTel]
        );

        const handleSend = () => {
            if (!phoneValue) return;
            const formattedPhone = phoneValue.replace(/\D/g, "");

            const isValid = matchIsValidTel(phoneValue) && !blackList.includes(formattedPhone);
            setIsValidPhone(isValid);
            if (isValid) {
                // if (ampli.isLoaded) {
                //     const defaultParams = {
                //         success: true,
                //         country_code: phoneInfo?.countryCode as string,
                //         entered_number: phoneValue as string,
                //         error_type: "",
                //     };

                //     if (pathname === AppRoutes.FIND_DEVICE) {
                //         ampli.numberLocateClicked({ ...defaultParams, mode: "locate by number" });
                //     }

                //     if (pathname === AppRoutes.LOST_PHONE) {
                //         ampli.numberLocateClicked({ ...defaultParams, mode: "lost my phone" });
                //     }
                // }

                setSearchPhoneNumber(phoneValue);
                setSearchCountryCode(phoneInfo?.countryCode || "");
                sendValueCallback?.(phoneInfo?.numberValue || "");
                onSubmit?.();
                if (redirectPath) navigate(redirectPath);
            }
        };

        const phoneLength = !phoneValue ? 0 : phoneInfo?.numberValue?.length || phoneValue?.length - 2 || 0;

        const Input = {
            dark: TelephoneInput,
            light: TelephoneInputLight,
        };

        const TelInput = Input[variant];

        return (
            <Box {...wrapperProps}>
                <Stack
                    sx={{
                        flexDirection: { xs: "column", lmd: "row" },
                        gap: "16px",
                    }}
                >
                    <TelInput
                        ref={inputRef}
                        flagSize="medium"
                        forceCallingCode
                        id="phone"
                        name="phone"
                        autoComplete="off"
                        placeholder={exampleNumber}
                        disabled={disabled}
                        value={phoneValue}
                        onChange={handleChange}
                        fullWidth
                        defaultCountry={ipInfo?.country || "US"}
                        helperText={isValidated && !isValidPhone ? t("Wrong phone") : ""}
                        error={isValidated && !isValidPhone}
                        loading={loading}
                    />
                    <Button
                        variant="themePrimary"
                        onClick={() => {
                            onUnsubscribe?.(phoneInfo?.numberValue) ?? handleSend();
                        }}
                        disabled={disabled || (!isValidInitValue && (phoneLength < 11 || !isValidated || !isValidPhone)) || (!checked && !hideDisclaimer)}
                        sx={{ width: isMobile ? "100%" : "350px", whiteSpace: "nowrap" }}
                        {...buttonProps}
                    >
                        {buttonProps?.children ?? t("Locate")}
                    </Button>
                </Stack>
                {!hideDisclaimer && (
                    <label style={{ display: "flex", marginTop: "16px" }}>
                        <CheckboxPrimary checked={checked} onChange={setChecked.toggle} />
                        <Typography variant="dmSansBody2Regular" sx={{ fontSize: "12px", color: "text.disabled", textAlign: "left", lineHeight: "normal" }}>
                            By clicking {buttonProps?.children ?? t("Locate")}, I agree to share my personal data to get personalized SMS to determine the
                            location of my mobile device by geolocation
                        </Typography>
                    </label>
                )}
            </Box>
        );
    }
);

export default TelephoneForm;
