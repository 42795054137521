import { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import DeleteItemPopup from "./DeleteItemPopup";
import RenameItemPopup from "./RenameItemPopup";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useBoolean } from "hooks/useBoolean";

const HistoryItemActions: FC<Record<"locationName" | "locationId", string>> = ({ locationName, locationId }) => {
    const { t } = useTranslation();
    const [isOpenDeletePopup, setDeletePopup] = useBoolean();
    const [isOpenRenamePopup, setRenamePopup] = useBoolean();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const stopPropagation = (event: MouseEvent<HTMLElement>) => event.stopPropagation();
    const handleClose = () => setAnchorEl(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        stopPropagation(event);
        setAnchorEl(event.currentTarget);
    };

    const onMenuItemClick = (mode: "delete" | "rename") => () => {
        handleClose();
        switch (mode) {
            case "delete":
                setDeletePopup.on();
                break;
            case "rename":
                setRenamePopup.on();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ padding: "0px", width: "16px", svg: { width: "100%" }, pointerEvents: "all" }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClick={stopPropagation}
                onClose={handleClose}
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                    sx: {
                        paddingY: "0px",
                        ".MuiMenuItem-root": {
                            borderRadius: "8px",
                            paddingY: "8px",
                            "&:hover": {
                                background: "#D0DFE1",
                            },
                        },
                    },
                }}
                slotProps={{
                    paper: {
                        sx: {
                            marginTop: "20px",
                            borderRadius: "8px",
                            padding: "4px 8px",
                        },
                    },
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <MenuItem onClick={onMenuItemClick("rename")}>
                    <Typography variant="dmSansBody1Regular">{t("Rename")}</Typography>
                </MenuItem>
                <MenuItem onClick={onMenuItemClick("delete")}>
                    <Typography variant="dmSansBody1Regular">{t("Delete")}</Typography>
                </MenuItem>
            </Menu>
            <DeleteItemPopup locationId={locationId} locationName={locationName} open={isOpenDeletePopup} onClose={setDeletePopup.off} />
            <RenameItemPopup locationId={locationId} locationName={locationName} open={isOpenRenamePopup} onClose={setRenamePopup.off} />
        </>
    );
};

export default HistoryItemActions;
