import { FC } from "react";
import { Controller, SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CountryCode, formatNumber, getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import { matchIsValidTel } from "mui-tel-input";
import { Box, DialogProps, Stack, Typography } from "@mui/material";
import { useAppData, useUserStore } from "providers/RootStoreProvider";
import { wrapPhoneOperatorIntoBrackets } from "helpers/wrapPhoneOperatorIntoBrackets";
import { SlideInPopup, CloseButton } from "components/SlideInPopup";
import { InputTextFieldLight, NumberFormatCustom } from "components/shared";
import { LoadingButton } from "components/LoadingButton";
import { TelephoneInputLight } from "components/TelephoneInput";
import { LostPhoneInfo } from "interfaces/location/location.interface";

interface FindDevicePopupProps extends DialogProps {
    onSuccess?: (data: LostPhoneInfo) => void;
}

const LostPhoneSendPopup: FC<FindDevicePopupProps> = ({ onSuccess, onClose, ...props }) => {
    const { searchPhoneNumber, ipInfo } = useAppData();
    const { user } = useUserStore();
    const { t } = useTranslation();
    const form = useForm<LostPhoneInfo>({
        defaultValues: {
            email: user?.email,
        },
    });

    const closePopup = () => {
        onClose?.({}, "backdropClick");
        form.reset();
    };

    const onSubmit: SubmitHandler<LostPhoneInfo> = async (data) => {
        if (!matchIsValidTel(data.alternativePhone)) {
            return form.setError("alternativePhone", { message: t("Wrong phone") });
        }

        try {
            await onSuccess?.(data);
            form.reset();
        } catch (error: any) {
            // const reason = error?.resBody?.error || error.message || "Wrong phone";
            form.setError("root", { message: t("Wrong phone") });
            // await handleAmpliLoaded(() => {
            //     ampli.messageSentFailure({ mode: "lost my phone", reason: reason });
            // });
        }
    };

    return (
        <SlideInPopup
            sx={(theme) => ({
                ".MuiDialog-paper": {
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    padding: "62px 81px 150px",
                },
                ".MuiDialogContent-root": {
                    paddingRight: "16px",
                    marginRight: "-16px",
                },
                [theme.breakpoints.down("lmd")]: {
                    ".MuiDialog-paper": {
                        overflow: "unset",
                        height: "90vh",
                        padding: "0 16px",
                        gap: "24px",
                    },
                    ".MuiDialogContent-root": {
                        overflowY: "auto",
                        paddingBottom: "20px",
                    },
                },
            })}
            {...props}
            onClose={closePopup}
            titleComponent={
                <Stack
                    sx={{
                        flexDirection: "column",
                        gap: "4px",
                        marginTop: { xs: "-50px", lmd: "unset" },
                    }}
                >
                    <CloseButton onClick={closePopup} />
                    <Box
                        sx={{
                            justifyContent: "center",
                            display: { xs: "flex", lmd: "none" },
                        }}
                    >
                        <img src="/images/send-lost-phone-message.png" alt="send message" style={{ height: "160px" }} />
                    </Box>
                    <Typography
                        component="p"
                        variant="dmSansH5Semibold"
                        sx={{
                            textAlign: "center",
                            margin: { xs: "-35px auto 0", lmd: "0 auto" },
                            color: "text.primary",
                            fontSize: { xs: "20px", lmd: "23px" },
                        }}
                    >
                        {t("Send a message to the number")}
                    </Typography>
                    <Typography component="p" variant="dmSansH4Semibold" sx={{ textAlign: "center", fontSize: { xs: "23px", lmd: "34px" } }} dir="ltr">
                        {wrapPhoneOperatorIntoBrackets(formatNumber(searchPhoneNumber, "INTERNATIONAL"))}
                    </Typography>
                </Stack>
            }
            contentComponent={<DetailsForm form={form} countryCode={ipInfo?.country} onSubmit={onSubmit} onClose={closePopup} />}
        />
    );
};

export default LostPhoneSendPopup;

interface DetailsFormProps {
    form: UseFormReturn<LostPhoneInfo>;
    countryCode?: CountryCode;
    onSubmit: SubmitHandler<LostPhoneInfo>;
    onClose?: () => void;
}

const DetailsForm: FC<DetailsFormProps> = ({ form, countryCode, onSubmit, onClose }) => {
    const { t } = useTranslation();

    const { handleSubmit, control, formState } = form;

    const placeholder = getExampleNumber(countryCode || "US", examples)?.formatNational();

    return (
        <Stack
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            sx={{
                gap: { xs: "16px", lmd: "24px" },
            }}
        >
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <InputTextFieldLight
                        label={t("Your name")}
                        placeholder={t("Name")}
                        {...field}
                        inputProps={{
                            maxLength: 50,
                        }}
                    />
                )}
            />
            <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <InputTextFieldLight label={t("Your e-mail")} type="email" placeholder={t("Email")} required autoComplete="new-password" {...field} />
                )}
            />
            <Controller
                name="alternativePhone"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ...filedProps }, fieldState: { error } }) => (
                    <TelephoneInputLight
                        label={t("Your alternative phone number")}
                        defaultCountry={countryCode || "US"}
                        placeholder={placeholder}
                        autoComplete="off"
                        fullWidth
                        required
                        forceCallingCode
                        className="lost-phone-input"
                        flagSize="medium"
                        sx={{
                            width: "100%",
                            "&.lost-phone-input .MuiInputBase-root": {
                                borderColor: "brandColors.brandLightgrey",
                                borderWidth: "2px",
                                height: "60px",
                                backgroundColor: "background.quaternary",
                                "&.Mui-focused": {
                                    borderColor: "brandColors.brandGreen",
                                },
                            },
                        }}
                        {...filedProps}
                        onChange={(_, info) => {
                            onChange(info.numberValue);
                        }}
                        error={!!error?.message}
                        helperText={error?.message}
                    />
                )}
            />
            <Controller
                name="reward"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ...fieldProps } }) => (
                    <InputTextFieldLight
                        label={t("Reward")}
                        placeholder="$100"
                        required
                        InputProps={{
                            inputComponent: NumberFormatCustom as any,
                        }}
                        inputProps={{
                            decimalScale: 2,
                            suffix: "$",
                            allowNegative: false,
                            onValueChange: (values) => {
                                onChange(values.formattedValue);
                            },
                        }}
                        {...fieldProps}
                    />
                )}
            />
            {!formState.errors.root ? (
                <LoadingButton
                    disabled={!formState.isValid}
                    loading={formState.isSubmitting}
                    type="submit"
                    fullWidth
                    variant="themePrimary"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    {t("Confirm and send")}
                </LoadingButton>
            ) : (
                <>
                    <Typography color="text.error" mb="1.5rem" align="center">
                        {t("Oops")}
                    </Typography>
                    <LoadingButton onClick={onClose} fullWidth loading={formState.isSubmitting} variant="themePrimary" sx={{ whiteSpace: "nowrap" }}>
                        {t("GO BACK")}
                    </LoadingButton>
                </>
            )}
        </Stack>
    );
};
