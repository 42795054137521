import { useAppData } from "providers/RootStoreProvider";
import { HeaderNonLegal } from "./HeaderNonLegal";
import { useEffect } from "react";
import { themeColors } from "constants/colors.const";

export const Header = () => {
    const { flowOuter } = useAppData();

    useEffect(() => {
        const metaThemeTag = document.querySelector("meta[name='theme-color']");
        if (metaThemeTag) {
            const metaThemeColor = flowOuter ? themeColors.text.fullBlack : themeColors.background.primary;
            metaThemeTag.setAttribute("content", metaThemeColor);
        }
    }, [flowOuter]);

    return <HeaderNonLegal />;
};
