import { Container, styled } from "@mui/material";

export const FormSection = styled("section")(({ theme }) => ({
    marginTop: "none",
    [theme.breakpoints.down("lmd")]: {
        marginTop: "-88px",
        backgroundImage: "url(/images/mobile-bg-features-form.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "left center",
    },
}));

export const FormContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "144px",
    marginBottom: "100px",
    gap: "32px",
    [theme.breakpoints.up("lmd")]: {
        alignItems: "center",
        textAlign: "center",
        background: theme.palette.background.lightAccent,
        maxWidth: "620px !important",
        marginTop: "24px",
        marginBottom: "24px",
        width: "100%",
        padding: "24px",
        borderRadius: "24px",
    },
}));
