import { AppRoutes } from "enums/routes.enum";
import { useUserStore } from "providers/RootStoreProvider";
import { removeTokensFromStorage } from "services/storage";
import { supabase } from "services/supabase";
import { useLocation } from "react-router-dom";
import { useCallback } from "react";

export const useLogout = () => {
    const { setUser } = useUserStore();
    const { pathname } = useLocation();

    // const mode = {
    //     [AppRoutes.FIND_DEVICE]: "locate by number",
    //     [AppRoutes.LOST_PHONE]: "lost my phone",
    // };

    const logout = useCallback(() => {
        supabase.auth
            .signOut()
            .then(() => {
                // handleAmpliLoaded(() =>
                //     ampli.accountLogoutClicked({
                //         mode: mode[pathname],
                //     })
                // );

                setUser(null);
                removeTokensFromStorage();

                window.location.assign(AppRoutes.LOG_IN);
            })
            .catch((error) => {
                console.error("error: ", error);
            });
    }, [pathname]);

    return logout;
};
