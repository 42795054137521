import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "libphonenumber-js";
import { Box, DialogProps, Stack, Typography } from "@mui/material";
import { LoadingButton } from "components/LoadingButton";
import { useAppData } from "providers/RootStoreProvider";
import { useBoolean } from "hooks/useBoolean";
import { wrapPhoneOperatorIntoBrackets } from "helpers/wrapPhoneOperatorIntoBrackets";
import { SlideInPopup, CloseButton } from "components/SlideInPopup";

interface FindDevicePopupProps extends DialogProps {
    onSuccess?: () => void;
}

const FindDeviceSendPopup: FC<FindDevicePopupProps> = ({ onSuccess, onClose, ...props }) => {
    const { searchPhoneNumber } = useAppData();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useBoolean();
    const [sendingError, setSendingError] = useBoolean();

    const closePopup = () => {
        onClose?.({}, "backdropClick");
        setSendingError.off();
    };

    const sentHandler = async () => {
        setIsLoading.on();
        try {
            await onSuccess?.();
        } catch (error: any) {
            setSendingError.on();
            // const reason = error?.resBody?.error || error.message || "Wrong phone";
            // await handleAmpliLoaded(() => {
            //     ampli.messageSentFailure({ mode: "locate by number", reason: reason });
            // });
        } finally {
            setIsLoading.off();
        }
    };

    return (
        <SlideInPopup
            sx={(theme) => ({
                ".MuiDialog-paper": {
                    overflow: "unset",
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px",
                    padding: "16px 81px 150px",
                },
                [theme.breakpoints.down("lmd")]: {
                    ".MuiDialog-paper": {
                        minHeight: "70vh",
                        padding: "0 16px",
                        gap: "24px",
                    },
                },
            })}
            {...props}
            onClose={closePopup}
            titleComponent={
                <Stack
                    sx={{
                        flexDirection: "column",
                        gap: "4px",
                        marginTop: { xs: "-50px", lmd: "unset" },
                    }}
                >
                    <CloseButton onClick={closePopup} />
                    <Box
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <img src="/images/send-message.png" alt="send message" style={{ height: "187px" }} />
                    </Box>
                    <Typography
                        component="p"
                        variant="dmSansH5Semibold"
                        sx={{
                            maxWidth: { xs: "326px", lmd: "none" },
                            textAlign: "center",
                            margin: { xs: "0 auto", lmd: "32px auto 0" },
                            color: { xs: "text.tertiary", lmd: "text.primary" },
                        }}
                    >
                        {t("This message will be sent to the number")}
                    </Typography>
                    <Typography component="p" variant="dmSansH4Semibold" textAlign="center" dir="ltr">
                        {wrapPhoneOperatorIntoBrackets(formatNumber(searchPhoneNumber, "INTERNATIONAL"))}
                    </Typography>
                </Stack>
            }
            contentComponent={
                sendingError ? (
                    <>
                        <Typography color="text.error" mb="1.5rem" align="center">
                            {t("Oops")}
                        </Typography>
                        <LoadingButton fullWidth loading={isLoading} variant="themePrimary" onClick={closePopup} sx={{ whiteSpace: "nowrap" }}>
                            {t("GO BACK")}
                        </LoadingButton>
                    </>
                ) : (
                    <LoadingButton fullWidth loading={isLoading} variant="themePrimary" onClick={sentHandler}>
                        {t("Send")}
                    </LoadingButton>
                )
            }
        />
    );
};

export default FindDeviceSendPopup;
