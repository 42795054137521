import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AccordionProps, Stack } from "@mui/material";
import { ILocationForLostPhone, LocationStatus } from "interfaces/location/location.interface";
import { HistoryItemAccordion, HistoryItemDetails, LocationInfoWrapper } from "../History.styled";
import HistoryItemTitle from "./HistoryItemTitle";
import HistoryDetails from "./HistoryDetails";
import HistoryItemRow from "./HistoryItemRow";
import { AccordionItemProps } from "./types";
import { formatPhone } from "../utils";

const LostPhoneAccordionItem: FC<AccordionItemProps<ILocationForLostPhone>> = ({ location, activeItem, setActiveItem, withMap }) => {
    const { t } = useTranslation();
    // const { featureKey } = useAccountFeature();
    const { status, name, id, details } = location;

    const isDisabled = [LocationStatus.SENT, LocationStatus.PENDING].includes(status);

    const onChange: AccordionProps["onChange"] = async (_, expanded) => {
        if (expanded) {
            setActiveItem(location);
            // await handleAmpliLoaded(() =>
            //     ampli.numberDetailsClicked({
            //         mode: featureKey,
            //         location_id: location.id,
            //         status: location.status,
            //     })
            // );
        } else {
            setActiveItem(null);
        }
    };

    return (
        <HistoryItemAccordion expanded={activeItem?.id === id} onChange={onChange} slotProps={{ transition: { unmountOnExit: true } }}>
            <HistoryItemTitle status={status} name={name} id={id} />
            <HistoryItemDetails>
                <LocationInfoWrapper gap="8px">
                    <HistoryItemRow
                        title={t("Number")}
                        value={
                            <Stack width="fit-content" dir="ltr">
                                {formatPhone(details?.searchNumber)}
                            </Stack>
                        }
                    />
                    <HistoryItemRow title={t("Name")} value={details.lostPhoneInfo.name} />
                    <HistoryItemRow title={t("Email")} value={details.lostPhoneInfo.email} />
                    <HistoryItemRow
                        title={t("Alternative phone")}
                        value={
                            <Stack width="fit-content" dir="ltr">
                                {formatPhone(details.lostPhoneInfo.alternativePhone)}
                            </Stack>
                        }
                    />
                    <HistoryItemRow title={t("Reward")} value={details.lostPhoneInfo.reward} />
                </LocationInfoWrapper>
                {!isDisabled && <HistoryDetails {...location} withMap={withMap} />}
            </HistoryItemDetails>
        </HistoryItemAccordion>
    );
};

export default LostPhoneAccordionItem;
