import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

interface HistoryItemRowProps {
    variant?: "default" | "small";
    title: string;
    value?: ReactNode;
}

const getOptionsByVariant = (variant: Required<HistoryItemRowProps["variant"]>) => {
    switch (variant) {
        case "default": {
            return {
                wrapper: { direction: "column" },
                title: {
                    variant: "dmSansBody2Semibold",
                    color: "text.tertiary",
                },
                value: { variant: "dmSansBody2Regular" },
            } as const;
        }
        case "small": {
            return {
                wrapper: { direction: "row" },
                title: {
                    variant: "dmSansBody2Regular",
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "text.disabled",
                },
                value: { variant: "dmSansBody2Regular", fontSize: "12px", textAlign: "right" },
            } as const;
        }
        default: {
            return {
                wrapper: {},
                title: {},
                value: {},
            } as const;
        }
    }
};

const HistoryItemRow: FC<HistoryItemRowProps> = ({ variant = "default", value, title }) => {
    const options = getOptionsByVariant(variant);

    if (!value) {
        return null;
    }

    return (
        <Stack rowGap="4px" columnGap="12px" justifyContent="space-between" {...options.wrapper}>
            <Typography {...options.title}>{title}:</Typography>
            <Typography {...options.value}>{value}</Typography>
        </Stack>
    );
};

export default HistoryItemRow;
