import { FC, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { LocationType } from "interfaces/location/location.interface";
import { TelephoneForm } from "components/TelephoneForm";
import { useAppData, useLocationsStore, useUserStore } from "providers/RootStoreProvider";
import { useBoolean } from "hooks/useBoolean";
import { AppRoutes } from "enums/routes.enum";
import { getLocationLink, sendLocationSms } from "http-client/location.client";
import SuccessPopup, { ScrollElementProps } from "./SuccessPopup";
import FindDeviceSendPopup from "./FindDeviceSendPopup";
import { FormContainer, FormSection } from "./Form.styled";
import { useHistoryFilter } from "../Filter";

const FindDeviceForm: FC<ScrollElementProps> = observer(({ scrollToElement }) => {
    const navigate = useNavigate();
    const { searchPhoneNumber, flowOuter } = useAppData();
    const { t, i18n } = useTranslation();
    const { userSubscription } = useUserStore();
    // const { featureKey } = useAccountFeature();
    const { overLimit, loading, fetchLocations } = useLocationsStore();
    const [isConfirmOpen, setConfirm] = useBoolean();
    const [isSuccessOpen, setSuccess] = useBoolean();

    const {
        state: { isActive },
        clearAll,
    } = useHistoryFilter();

    const onLocate = useCallback(() => {
        if (!userSubscription?.isPremium) {
            return navigate(AppRoutes.PAYMENT);
        }

        setConfirm.on();
    }, [userSubscription?.isPremium]);

    const onSuccess = async () => {
        const link = await getLocationLink({
            name: searchPhoneNumber,
            type: LocationType.FIND_DEVICE,
        });
        const locationId = link?.data?.id;

        if (locationId) {
            await sendLocationSms({ locationId, language: i18n.language, flowOuter: flowOuter ?? false });
        }
        // await handleAmpliLoaded(() =>
        //     ampli.messageSentSuccess({
        //         mode: featureKey,
        //     })
        // );

        if (isActive) {
            clearAll();
        } else {
            await fetchLocations({ type: LocationType.FIND_DEVICE });
        }
        setConfirm.off();
        setSuccess.on();
    };

    const onSendPopupClose = async () => {
        // await handleAmpliLoaded(() => {
        //     ampli.messageExit({ mode: featureKey });
        // });
        setConfirm.off();
    };

    return (
        <FormSection>
            <FormContainer maxWidth="lg">
                <Typography component="h1" variant="dmSansH4Semibold" sx={{ color: { xs: "text.secondary", lmd: "text.primary" } }}>
                    {t("Enter the number")}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        flexDirection: { xs: "column", lmd: "row" },
                        gap: "16px",
                        height: { xs: "155px", lmd: "unset" },
                        ".MuiInputBase-root": { height: "60px !important" },
                    }}
                >
                    <TelephoneForm
                        onSubmit={onLocate}
                        disabled={overLimit || loading}
                        initValue={searchPhoneNumber}
                        buttonProps={{
                            sx: {
                                width: { xs: "100%", sm: "350px", lmd: "100%" },
                                padding: "18px",
                                height: "60px",
                            },
                        }}
                    />
                </Box>
            </FormContainer>
            <FindDeviceSendPopup open={isConfirmOpen} onClose={onSendPopupClose} onSuccess={onSuccess} />
            <SuccessPopup open={isSuccessOpen} onClose={setSuccess.off} scrollToElement={scrollToElement} />
        </FormSection>
    );
});

export default FindDeviceForm;
