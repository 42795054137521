import { FC, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";

import { ILocation } from "interfaces/location/location.interface";
import { GOOGLE_MAP_API_KEY } from "constants/env.const";
import { darkTheme } from "constants/googleMapTheme.const";

interface MapProps {
    location: ILocation;
    width?: string;
    height?: string;
    borderRadius?: string;
    withMarker?: boolean;
}

export const GMap: FC<MapProps> = ({ location, width, height, borderRadius, withMarker = true }) => {
    const position = {
        lat: location.latitude ? +location.latitude : 0,
        lng: location.longitude ? +location.longitude : 0,
    };

    const { isLoaded, loadError } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_API_KEY,
    });

    const onLoad = useCallback(
        (map: google.maps.Map) => {
            map.setOptions({ minZoom: 12, maxZoom: 16 });

            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            const bounds = new window.google.maps.LatLngBounds(position);
            map.fitBounds(bounds);

            setTimeout(() => {
                map.setOptions({ maxZoom: 20 });
            }, 500);
        },
        [position]
    );

    if (loadError) return <div>Error loading maps</div>;

    if (!isLoaded) return <CircularProgress />;

    return (
        <GoogleMap
            mapContainerStyle={{
                width: width || "100%",
                height: height || "100%",
                borderRadius: borderRadius || 0,
            }}
            center={position}
            zoom={18}
            onLoad={onLoad}
            options={{
                styles: darkTheme,
                keyboardShortcuts: false,
                streetViewControl: false,
                scaleControl: false,
                zoomControl: false,
                mapTypeControl: false,
                panControl: false,
                rotateControl: false,
                fullscreenControl: true,
                backgroundColor: "#242f3e",
            }}
        >
            {withMarker && <Marker position={position} />}
        </GoogleMap>
    );
};

export default GMap;
