import { FC, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Typography, TypographyProps } from "@mui/material";
import { useLocationsStore } from "providers/RootStoreProvider";
import { TypographyWithHighlight } from "../../../components/shared";

const HistoryLimit: FC<Omit<TypographyProps, "children">> = observer((props) => {
    const { limit, dailyLocationsMap, overLimit } = useLocationsStore();
    const { t } = useTranslation();

    const requestsToday = dailyLocationsMap.size;

    const renderContent = useCallback(() => {
        if (overLimit) {
            return (
                <Typography variant="inherit" color="text.error">
                    {t("Your daily limit has been reached. Please try again tomorrow")}
                </Typography>
            );
        }

        const critical = limit <= requestsToday + 4;

        if (critical) {
            return (
                <TypographyWithHighlight
                    text={t("requests_limit", {
                        count: requestsToday,
                        limit,
                    })}
                    highlight={requestsToday.toString()}
                    highlightProps={{
                        variant: "inherit",
                        color: "text.error",
                        fontWeight: 600,
                    }}
                />
            );
        }

        return (
            <TypographyWithHighlight
                color="text.tertiary"
                text={t("requests_limit", {
                    count: requestsToday,
                    limit,
                })}
                highlight={requestsToday.toString()}
                highlightProps={{
                    variant: "inherit",
                    fontWeight: 600,
                }}
            />
        );
    }, [overLimit, requestsToday, limit]);

    return (
        <Typography component="h2" paddingX="16px" variant="dmSansBody2Regular" align="center" {...props}>
            {renderContent()}
        </Typography>
    );
});

export default HistoryLimit;
