import { PageFallback } from "components/PageFallback";
import { lazy, Suspense } from "react";

let Home = lazy(() => import("./Home"));

export async function lazyHomeLoader() {
    const componentModule = await import("./Home");
    // This avoid flicker from React.lazy by using the component directly
    Home = componentModule.default as any;

    return null;
}

export function LazyHome() {
    return (
        <Suspense fallback={<PageFallback />}>
            <Home />
        </Suspense>
    );
}
