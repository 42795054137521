import { Stack } from "@mui/material";
import { useBoolean } from "hooks/useBoolean";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { BurgerMenu, LogoutButton } from "components/shared";

import ProfileButton from "./ProfileButton";
import { DesktopMenuContainer, DesktopMenuWrapper } from "./Menu.styled";

import { ReactComponent as LogoWhiteIcon } from "assets/icons/logo-white.svg";
import { AccountContactUs } from "./AccountContactUs";

const DesktopMenu = () => {
    const [open, setOpen] = useBoolean();
    const { isDesktop } = useDeviceDetect();

    // const onLanguageSelect = (id: Languages) => {
    //     saveLanguage(id.toUpperCase());
    //     setOpen.off();
    // };

    return (
        <DesktopMenuWrapper>
            <DesktopMenuContainer maxWidth="lg">
                <LogoWhiteIcon />
                {isDesktop ? (
                    <>
                        {/* <FeatureLinks /> */}
                        <Stack justifyContent="flex-end" direction="row" gap="16px">
                            {/* <LanguagePicker /> */}
                            <ProfileButton />
                        </Stack>
                    </>
                ) : (
                    <Stack alignItems="flex-end">
                        <BurgerMenu open={open} onClose={setOpen.toggle} sx={{ "& .MuiPaper-root": { paddingTop: "80px", gap: "24px" } }}>
                            {/* <LanguageAccordion onSelect={onLanguageSelect} /> */}
                            <AccountContactUs />
                            <LogoutButton />
                        </BurgerMenu>
                    </Stack>
                )}
            </DesktopMenuContainer>
        </DesktopMenuWrapper>
    );
};

export default DesktopMenu;
