import { useCallback } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistoryFilter } from "../Filter";
import { TypographyWithHighlight } from "components/shared";

const HistoryEmpty = () => {
    const { t } = useTranslation();
    const {
        state: { isActive },
    } = useHistoryFilter();

    const renderContent = useCallback(() => {
        if (isActive) {
            return (
                <TypographyWithHighlight
                    variant="inherit"
                    text={t("Sorry, there are no phone numbers matching the current filter")}
                    highlight={t("Current.highlighted")}
                    highlightProps={{
                        color: "brandColors.brandGreen",
                    }}
                />
            );
        }

        return t("No phone numbers in history yet");
    }, [isActive, t]);

    return (
        <Typography variant="dmSansH5Semibold" color="text.tertiary" padding="16px" textAlign="center" margin="0 auto" maxWidth="320px">
            {renderContent()}
        </Typography>
    );
};

export default HistoryEmpty;
