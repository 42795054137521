import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import { wrapPhoneOperatorIntoBrackets } from "helpers/wrapPhoneOperatorIntoBrackets";
import { ILocation } from "interfaces/location/location.interface";
import { formatNumber } from "libphonenumber-js";
import { LocationStatusChip } from "components/shared";
import HistoryItemActions from "./HistoryItemActions";
import { HistoryItemTrigger } from "../History.styled";

const HistoryItemTitle: FC<Pick<ILocation, "status" | "name" | "id">> = ({ name, status, id }) => {
    const locationName = useMemo(() => {
        const number = formatNumber(name, "INTERNATIONAL");

        if (!number) {
            return name;
        }

        return wrapPhoneOperatorIntoBrackets(number);
    }, [name]);

    return (
        <HistoryItemTrigger aria-controls={`location-${id}-content`} id={`location-${id}-header`}>
            {name && (
                <Typography flex="1" variant="dmSansBody2Semibold" dir="ltr">
                    {locationName}
                </Typography>
            )}
            <LocationStatusChip status={status} />
            <HistoryItemActions locationId={id} locationName={name} />
        </HistoryItemTrigger>
    );
};

export default HistoryItemTitle;
