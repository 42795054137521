import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogProps, Stack, Typography } from "@mui/material";
import { SlideInPopup, CloseButton } from "components/SlideInPopup";
import { TypographyWithHighlight } from "components/shared";
import { useLocationsStore } from "providers/RootStoreProvider";
import { useBoolean } from "hooks/useBoolean";

type DeleteItemPopupProps = DialogProps & Record<"locationName" | "locationId", string>;

const DeleteItemPopup: FC<DeleteItemPopupProps> = ({ onClose, locationId, locationName, ...props }) => {
    const [isFetching, setFetching] = useBoolean();
    const { t } = useTranslation();
    // const { featureKey } = useAccountFeature();
    const { deleteLocationAction } = useLocationsStore();

    const closePopup = useCallback(
        (event, reason?: "backdropClick" | "escapeKeyDown") => {
            if (isFetching) {
                return;
            }
            onClose?.(event, reason ?? "backdropClick");
        },
        [isFetching]
    );

    const handleDelete = async (event) => {
        try {
            setFetching.on();
            await deleteLocationAction(locationId);

            // await handleAmpliLoaded(() => ampli.numberNameDeleted({ mode: featureKey }));

            setFetching.off();
            closePopup(event);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <SlideInPopup
            slotProps={{
                root: {
                    onClick: (e) => e.stopPropagation(),
                },
            }}
            onClose={onClose}
            sx={(theme) => ({
                ".MuiDialog-paper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "44px",
                    padding: "38px 45px",
                },
                [theme.breakpoints.down("lmd")]: {
                    ".MuiDialog-paper": {
                        overflow: "unset",
                        minHeight: "90vh",
                        padding: "44px 16px 16px",
                    },
                },
            })}
            {...props}
            titleComponent={
                <Stack
                    sx={{
                        textAlign: { xs: "center", lmd: "left" },
                        gap: { xs: "4px", lmd: "16px" },
                        alignItems: { xs: "center", lmd: "flex-start" },
                    }}
                >
                    <CloseButton onClick={closePopup} />
                    <TypographyWithHighlight
                        component="span"
                        variant="dmSansH5Semibold"
                        fontSize="20px"
                        text={t("delete_popup_title", { name: locationName })}
                        highlight={locationName}
                        highlightProps={{
                            dir: "ltr",
                            sx: { whiteSpace: "nowrap" },
                        }}
                    />
                    <Typography variant="dmSansBody1Regular" maxWidth="420px">
                        {t("delete_popup_subtitle")}
                    </Typography>
                </Stack>
            }
            contentComponent={
                <Stack sx={{ gap: { xs: "16px", lmd: "24px" }, flexDirection: { xs: "column", lmd: "row-reverse" } }}>
                    <Button
                        variant="themePrimary"
                        disabled={isFetching}
                        onClick={handleDelete}
                        sx={{ whiteSpace: "nowrap", padding: "18px 40px", minWidth: "unset", height: "60px" }}
                    >
                        {t("Delete")}
                    </Button>
                    <Button
                        onClick={closePopup}
                        fullWidth
                        variant="outlined"
                        disabled={isFetching}
                        sx={(theme) => ({
                            color: theme.palette.text.disabled,
                            borderColor: theme.palette.text.disabled,
                            background: "none",
                            borderWidth: "2px",
                            width: "fit-content",
                            minWidth: { xs: "100%", lmd: "186px" },
                        })}
                    >
                        {t("Cancel")}
                    </Button>
                </Stack>
            }
        />
    );
};

export default DeleteItemPopup;
