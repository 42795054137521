import { Box, Stack, Typography } from "@mui/material";
import { ContactUsForm } from "components/ContactUsPopup/ContactUsForm";
import { TypographyWithHighlight } from "components/shared";
import { CloseButton, SlideInPopup } from "components/SlideInPopup";
import { FC, ReactNode } from "react";
import { SlideInPopupProps } from "components/SlideInPopup/SlideInPopup";
import { useDeviceDetect } from "hooks/useDeviceDetect";
import { themeColors } from "constants/colors.const";
import { useAppData } from "providers/RootStoreProvider";
import { useBoolean } from "hooks/useBoolean";
import { useTranslation } from "hooks/useTranslation";

type SelectedPopupProps = Pick<SlideInPopupProps, "sx" | "className">;
export interface ContactUsPopupProps extends SelectedPopupProps {
    image?: string;
    popupTrigger?: ReactNode;
    feature?: string;
}

export const ContactUsPopup: FC<ContactUsPopupProps> = ({ image, popupTrigger, sx, ...rest }) => {
    const {
        t,
        detect: { isArabicLanguage },
    } = useTranslation();
    const { isTablet } = useDeviceDetect();
    const { flowOuter, abTestData } = useAppData();
    // const { user } = useUserStore();
    // const isLoggedIn = !!user?.email;
    const [popupOpen, setPopupOpen] = useBoolean(false);
    const [success, setSuccess] = useBoolean(false);
    const abTestContact = abTestData?.["abTestContact"];
    const isAbTestContact = abTestContact === "1";
    const showContactsPopup = isAbTestContact && flowOuter;

    // useEffect(() => {
    //     if (!popupOpen) return;

    //     // const params = {
    //     //     mode: feature,
    //     //     user_status: isLoggedIn ? "logged_in" : "logged_out",
    //     // };
    //     // handleAmpliLoaded(() => ampli.contactFormShown(params));
    // }, [popupOpen]);

    if (!showContactsPopup) return null;

    return (
        <>
            <Box onClick={setPopupOpen.on}>{popupTrigger}</Box>
            <SlideInPopup
                onClose={setPopupOpen.off}
                open={popupOpen}
                sx={{
                    "& .MuiPaper-root": {
                        display: { xs: "block", lmd: "flex" },
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "80vh",
                        padding: { xs: isArabicLanguage ? "92px 16px 29px 0" : "92px 0 29px 16px", lmd: "36px 56px" },
                        background: { xs: "", lmd: themeColors.brandColors.brandWhite },
                        position: "relative",
                        maxWidth: { xs: "100%", lmd: "90%" },
                        overflowY: { xs: "visible", lmd: "unset" },
                    },
                    "& .MuiDialogContent-root": {
                        overflowY: { xs: "unset", lmd: "auto" },
                        maxWidth: "100%",
                    },
                    ...sx,
                }}
                {...rest}
                contentComponent={
                    <>
                        {success ? (
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                gap="32px"
                                sx={(theme) => ({
                                    padding: { xs: "144px 16px 106px", lmd: "160px 128px 190px" },
                                    borderRadius: "30px",
                                    background: { xs: image ? "transparent" : theme.palette.background.lightAccent, lmd: theme.palette.background.lightAccent },
                                    width: "fit-content",
                                    margin: { xs: "auto 16px auto auto", lmd: "auto" },
                                })}
                            >
                                <TypographyWithHighlight
                                    text={t("Thank you!")}
                                    highlight="!"
                                    variant="dmSansH4Semibold"
                                    color="text.black"
                                    sx={{ fontSize: "57px", textAlign: "center", lineHeight: 1.1 }}
                                    highlightProps={{
                                        sx: {
                                            background: "linear-gradient(45deg, #5E82F6 -0.09%, #865EF6 99.91%)",
                                            backgroundClip: "text",
                                            "-webkit-text-fill-color": "transparent",
                                        },
                                    }}
                                />
                                <Typography
                                    variant="dmSansH5Regular"
                                    sx={(theme) => ({ color: theme.palette.text.tertiary, whiteSpace: "pre-line", textAlign: "center" })}
                                >
                                    {t("contact_form_success_subtitle")}
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack
                                sx={{
                                    flexDirection: { xs: "column", lmd: "row" },
                                    overflowY: { xs: "unset", lmd: "auto" },
                                }}
                            >
                                <Stack sx={{ width: { xs: "100%", lmd: "40%" }, pr: { xs: 0, lmd: "32px" }, zIndex: 2 }}>
                                    <TypographyWithHighlight
                                        text={t("Contact Us.")}
                                        highlight="."
                                        variant="dmSansH4Semibold"
                                        color="text.primary"
                                        sx={{
                                            fontSize: { xs: "48px", lmd: "57px" },
                                            mb: "10px",
                                            mt: { xs: 0, lmd: "170px" },
                                            padding: { xs: isArabicLanguage ? "0 0 0 16px" : "0 16px 0 0", lmd: 0 },
                                        }}
                                        highlightProps={{
                                            sx: {
                                                background: "linear-gradient(45deg, #5E82F6 -0.09%, #865EF6 99.91%)",
                                                backgroundClip: "text",
                                                "-webkit-text-fill-color": "transparent",
                                            },
                                        }}
                                    />
                                    <Typography
                                        variant="dmSansH5Regular"
                                        sx={(theme) => ({
                                            color: theme.palette.text.tertiary,
                                            lineHeight: "29px",
                                            padding: { xs: isArabicLanguage ? "0 0 0 16px" : "0 16px 0 0", lmd: 0 },
                                        })}
                                    >
                                        {t("Contact our support team, and we'll address all your inquiries")}
                                    </Typography>
                                </Stack>
                                <Box
                                    sx={(theme) => ({
                                        padding: { xs: isArabicLanguage ? "20px 0 100px 16px" : "20px 16px 110px 0", lmd: "72px 69px 66px" },
                                        width: { xs: "100%", lmd: "60%" },
                                        borderRadius: { xs: 0, lmd: "30px" },
                                        background: { xs: "none", lmd: theme.palette.background.lightAccent },
                                        maxHeight: { xs: image ? "55vh" : "65vh", lmd: "unset" },
                                        py: "1rem",
                                        overflowY: { xs: "auto", lmd: "unset" },
                                        "&::-webkit-scrollbar": isArabicLanguage ? { display: "none" } : {},
                                    })}
                                >
                                    <ContactUsForm onSuccess={setSuccess.on} />
                                </Box>
                            </Stack>
                        )}
                    </>
                }
            >
                <CloseButton onClick={setPopupOpen.off} />
                {image && isTablet && (
                    <img style={{ position: "absolute", left: "50%", top: "-23px", width: "154px", transform: "translateX(-50%)" }} src={image} />
                )}
            </SlideInPopup>
        </>
    );
};
