import { FC, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "libphonenumber-js";
import { Button, DialogProps, Stack, SvgIcon, Typography } from "@mui/material";
import { SlideInPopup } from "components/SlideInPopup";
import { wrapPhoneOperatorIntoBrackets } from "helpers/wrapPhoneOperatorIntoBrackets";
import { useAppData } from "providers/RootStoreProvider";

import { ReactComponent as SuccessIcon } from "assets/icons/search-checked.svg";

export interface ScrollElementProps {
    scrollToElement?: RefObject<HTMLElement>;
}

type SuccessPopupProps = DialogProps & ScrollElementProps;

const SuccessPopup: FC<SuccessPopupProps> = ({ onClose, scrollToElement, ...props }) => {
    const { t } = useTranslation();
    const { searchPhoneNumber, setSearchPhoneNumber } = useAppData();

    const closePopup = () => {
        onClose?.({}, "backdropClick");
        setSearchPhoneNumber("");
    };

    const onCheckStatus = () => {
        closePopup();
        if (scrollToElement?.current) {
            scrollToElement?.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    const onNewSearch = () => {
        closePopup();
        scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <SlideInPopup
            sx={(theme) => ({
                ".MuiDialog-paper": {
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    padding: "0 81px 150px",
                },
                [theme.breakpoints.down("lmd")]: {
                    ".MuiDialog-paper": {
                        overflow: "unset",
                        minHeight: "70vh",
                        padding: "0 16px",
                    },
                },
            })}
            {...props}
            titleComponent={
                <Stack alignItems="center" sx={{ marginBottom: { xs: "16px", lmd: "44px" }, gap: { xs: "12px", lmd: "44px" } }}>
                    <SvgIcon
                        sx={(theme) => ({
                            width: "144px",
                            height: "144px",
                            [theme.breakpoints.down("lmd")]: {
                                width: "154px",
                                height: "154px",
                                backgroundColor: theme.palette.text.secondary,
                                borderRadius: "16px",
                                padding: "16px",
                                marginTop: "-20px",
                            },
                        })}
                    >
                        <SuccessIcon />
                    </SvgIcon>
                    <Stack alignItems="center">
                        <Typography component="span" variant="dmSansH4Semibold" marginBottom="4px">
                            {t("Success")}!
                        </Typography>
                        <Typography variant="dmSansBody1Regular" align="center" dir="auto">
                            {t("Send message to")}&nbsp;
                            <strong>
                                <span dir="ltr">{wrapPhoneOperatorIntoBrackets(formatNumber(searchPhoneNumber, "INTERNATIONAL"))}</span>
                            </strong>
                        </Typography>
                        <Typography variant="dmSansBody1Regular" align="center">
                            {t("Your identity remains confidential. The message will be sent anonymously.")}
                        </Typography>
                    </Stack>
                </Stack>
            }
            contentComponent={
                <Stack sx={{ gap: { xs: "16px", lmd: "24px" } }}>
                    <Button onClick={onCheckStatus} fullWidth variant="themePrimary" sx={{ whiteSpace: "nowrap" }}>
                        {t("Check the status")}
                    </Button>
                    <Button
                        onClick={onNewSearch}
                        fullWidth
                        variant="outlined"
                        sx={(theme) => ({
                            color: theme.palette.text.tertiary,
                            background: theme.palette.background.whiteOpacity4,
                            height: "44px",
                        })}
                    >
                        {t("NEW SEARCH")}
                    </Button>
                </Stack>
            }
        />
    );
};

export default SuccessPopup;
