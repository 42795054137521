import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/material";
import { useLocationsStore } from "providers/RootStoreProvider";
import { DesktopMenu } from "./Menu";
import LostPhone from "../LostPhone";
import { AccountFeatureProvider } from "../context";
import { LandingMode } from "constants/landing-variants.const";

const AccountLayout = observer(() => {
    const { fetchAllLocations } = useLocationsStore();

    useEffect(() => {
        fetchAllLocations();
    }, []);

    return (
        <AccountFeatureProvider featureKey={LandingMode.LOST_MY_PHONE_MODE}>
            <Box minHeight="100svh" position="relative" display="flex" flexDirection="column">
                <DesktopMenu />
                <LostPhone />
            </Box>
        </AccountFeatureProvider>
    );
});

export default AccountLayout;
